<script setup>
import { computed, ref, onMounted } from 'vue';
import { useAuthentication } from '@/composables/use-authentication';
import VueJitsiMeet from '@/components/VueJitsiMeet.vue';

const emit = defineEmits(['videoConferenceLeft']);

const props = defineProps({
  room: {
    type: String  ,
    default: 'consult-demo',
  },
});

const { currentUser, init: initCurrentUser } = useAuthentication();

const fullName = computed(() => {
  const name = currentUser.value?.name;
  return `${name?.firstName} ${name?.lastName}`;
});
const email = computed(() => currentUser.value?.email);
const picURL = computed(() => currentUser.value?.picURL);

const jitsiOptions = {
  roomName: props.room,
  parentNode: document.querySelector('#jitsi-mount-point'),
  userInfo: {
    displayName: fullName.value,
    email: email?.email,
  },
  configOverwrite: {
    enableNoisyMicDetection: true,
    disablePolls: true,
  },
  interfaceConfigOverwrite: {
    JITSI_WATERMARK_LINK: 'https://parmazip.ca',
    SHOW_JITSI_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    MOBILE_APP_PROMO: false,
    DISPLAY_WELCOME_PAGE_CONTENT: false,
    SETTINGS_SECTIONS: [],
    CLOSE_PAGE_GUEST_HINT: false,
    TOOLBAR_BUTTONS: [
      'microphone', 
      'camera', 
      'closedcaptions', 
      'desktop', 
      'fullscreen',
      'chat',
      'tileview',
      // 'hangup',
      // 'recording',
      // 'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
      // 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
      // 'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
      // 'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
      // 'e2ee',
    ],
  },
};

const jitsiRef = ref(null);

const events = {
  videoConferenceJoined: () => {
    emit('joined');
  },
  videoConferenceLeft: (event) => {
    emit('videoConferenceLeft', event)
  },
}

function runCommands () {
  jitsiRef.value.embedJitsiWidget();
  jitsiRef.value.executeCommand('avatarUrl', picURL.value);
  jitsiRef.value.executeCommand('displayName', fullName.value);
  jitsiRef.value.executeCommand('localSubject', 'Parmazip Virtual Consultation');
  jitsiRef.value.executeCommand('toggleAudio');
  
  for (const eventName in events) {
    jitsiRef.value.addEventListener(eventName, events[eventName]);
  }
}

// function removeJitsi () {
//   jitsiRef.value.removeJitsiWidget();
//   for (const eventName in events) {
//     jitsiRef.value.removeEventListener(eventName, events[eventName]);
//   }
// }

onMounted(async () => {
  await initCurrentUser();
  runCommands();
});

// onUnmounted(() => {
//   removeJitsi();
// });
</script>

<template>
  <VueJitsiMeet domain="jitsi.parmazip.com" ref="jitsiRef" :options="jitsiOptions"  />
</template>
